<template>
	<a-layout class="container">
		<a-layout-content class="content">
			<a-row class="sign-header">
				<img alt="logo" class="logo" src="../assets/mib-logo.png" />
				<span class="title">{{ setting.systemName }}</span>
			</a-row>
			<div class="login">
				<div v-if="!isForgotPassword">
					<a-row class="sign-header desc">{{ $t('operate.loginTab1') }}</a-row>
					<a-form :form="form" @submit="onSubmit">
						<a-alert type="error" :closable="true" v-show="error" :message="error" showIcon style="margin-bottom: 24px" />
						<a-form-item>
							<a-input
								size="large"
								placeholder="Username"
								@change="checkUserAccount"
								v-decorator="[
									'userAccount',
									{
										rules: [
											{
												required: true,
												message: 'please enter your username',
												whitespace: true
											}
										]
									}
								]"
							>
								<a-icon slot="prefix" type="user" />
							</a-input>
							<div class="tips-icon" v-if="isResetPassword && verifyUserAccount !== null">
								<a-icon class="right-icon suc-icon" v-if="verifyUserAccount" type="check-circle" theme="filled" />
								<div v-else
									><a-icon class="right-icon err-icon" type="close-circle" theme="filled" /><span>{{ $t('toast.msg9') }}</span></div
								>
							</div>
						</a-form-item>
						<a-form-item>
							<a-input-password
								size="large"
								placeholder="Password"
								type="password"
								@change="checkUserPassword"
								autocomplete
								v-decorator="[
									'userPassword',
									{
										rules: [
											{
												required: true,
												message: 'Please enter the password',
												whitespace: true
											}
										]
									}
								]"
							>
								<a-icon slot="prefix" type="lock" />
							</a-input-password>
							<div class="tips-icon" v-if="isResetPassword && verifyUserPassword !== null">
								<a-icon class="right-icon suc-icon" v-if="verifyUserPassword" type="check-circle" theme="filled" />
								<div v-else
									><a-icon class="right-icon err-icon" type="close-circle" theme="filled" /><span>{{ $t('toast.msg10') }}</span></div
								>
							</div>
						</a-form-item>
						<a-form-item v-if="isResetPassword">
							<a-input-password
								:maxLength="30"
								size="large"
								placeholder="Reset Password 6+ Characters"
								type="password"
								@change="checkNewPassword"
								autocomplete
								v-decorator="[
									'newPassword',
									{
										rules: [
											{
												required: true,
												message: 'Please enter the reset password',
												whitespace: true
											}
										]
									}
								]"
							>
								<a-icon slot="prefix" type="redo" />
							</a-input-password>

							<div class="tips-icon">
								<!-- <span class="tips" v-if="verifyNewPassword === null">{{ $t('toast.msg11') }}</span> -->
								<a-icon class="right-icon suc-icon" v-if="verifyNewPassword" type="check-circle" theme="filled" />
								<div v-if="verifyNewPassword === false">
									<a-icon class="right-icon err-icon" type="close-circle" theme="filled" /><span>{{ $t('toast.msg12') }}</span>
								</div>
							</div>
						</a-form-item>
						<a-form-item v-if="isResetPassword">
							<a-input-password
								:maxLength="30"
								size="large"
								placeholder="Confirm Password"
								type="password"
								@change="checkRePassword"
								autocomplete
								v-decorator="[
									'reNewPassword',
									{
										rules: [
											{
												required: true,
												message: 'Please enter the confirm password',
												whitespace: true
											}
										]
									}
								]"
							>
								<a-icon slot="prefix" type="check-circle" />
							</a-input-password>
							<div class="tips-icon" v-if="isResetPassword">
								<!-- <span class="tips" v-if="verifyRePassword === null">{{ $t('toast.msg11') }}</span> -->
								<a-icon class="right-icon suc-icon" v-if="verifyRePassword" type="check-circle" theme="filled" />
								<div v-if="verifyRePassword === false">
									<a-icon class="right-icon err-icon" type="close-circle" theme="filled" /><span>{{ $t('toast.msg13') }}</span>
								</div>
							</div>
						</a-form-item>
						<a-row>
							<a-col :span="12">
								<a-dropdown>
									<a class="able" style="line-height: 32px">
										<span>{{ $t('Language') }} | {{ setting.curLanguage.text }}</span>
									</a>
									<a-menu slot="overlay">
										<a-menu-item v-for="(item, index) in setting.languages" :key="index">
											<a @click="setLanguage(index)">
												<Icon-svg :iconClass="item.country"></Icon-svg>
												<span>{{ item.text }}</span>
											</a>
										</a-menu-item>
									</a-menu>
								</a-dropdown>
							</a-col>
							<a-col class="able" :span="12" style="text-align: right">
								<a-button type="link" style="color: #00b0ff; padding: 0" @click.native="resetPassword">{{ $t('operate.resetPassword') }}</a-button> |
								<a-button type="link" style="color: #00b0ff; padding: 0" @click.native="forgotPassword">Forgot Password</a-button>
							</a-col>
						</a-row>
						<a-row>
							<a-button :loading="logging" style="width: 100%; margin-top: 24px" size="large" html-type="submit" type="primary">{{
								isResetPassword ? $t('page.confirmBtn') : $t('operate.login')
							}}</a-button>
						</a-row>
					</a-form>
				</div>
				<div v-else class="forgot-password">
					<a-row class="title"
						><a-icon v-if="forgotPasswordStep.type === 4" type="check-circle" style="margin-right: 20px; color: green" /><span>{{
							forgotPasswordStep.title
						}}</span></a-row
					>
					<a-row class="subTitle" v-html="forgotPasswordStep.subTitle"></a-row>
					<a-form :form="forgotForm" layout="vertical">
						<template v-if="forgotPasswordStep.type === 1">
							<a-form-item label="User Name">
								<a-input
									size="large"
									placeholder="Please Enter Your User Name"
									v-decorator="[
										'userName',
										{
											rules: [
												{
													required: true,
													message: 'Please Enter Your User Name',
													whitespace: true
												}
											]
										}
									]"
								/>
							</a-form-item>
							<a-form-item label="Mobile">
								<a-input
									addon-before="0"
									:maxLength="9"
									size="large"
									placeholder="Please Enter Your Mobile Number"
									@change="checkMobile"
									v-decorator="[
										'mobileNumber',
										{
											rules: [
												{
													required: true,
													message: 'Please Enter Your Mobile Number',
													whitespace: true
												},
												{
													len: 9,
													message: 'Please check your mobile again'
												}
											]
										}
									]"
								/>
							</a-form-item>
						</template>
						<template v-if="forgotPasswordStep.type === 2">
							<a-form-item>
								<a-input
									size="large"
									:maxLength="6"
									placeholder="Please Enter Code Here"
									v-decorator="[
										'codeNumber',
										{
											rules: [
												{
													required: true,
													message: 'Please Enter Code',
													whitespace: true
												},
												{
													len: 6,
													message: 'The code should be 6 digit'
												}
											]
										}
									]"
								/>
								<p>Did't receive the code?<a-button type="link" @click.native="getApiSendCode">Click to resend</a-button></p>
							</a-form-item>
						</template>
						<template v-if="forgotPasswordStep.type === 3">
							<a-form-item label="Reset Password">
								<a-input-password
									:maxLength="30"
									size="large"
									placeholder="Please Enter The Reset Password"
									type="password"
									@change="checkResetPassword"
									v-decorator="[
										'resetPassword',
										{
											rules: [
												{
													required: true,
													message: 'Please Enter The Reset Password',
													whitespace: true
												},
												{
													min: 6,
													message: 'Must be at least 6 characters'
												}
											]
										}
									]"
								/>
								<div class="tips-icon" v-if="verifyResetPassword !== null">
									<a-icon class="right-icon suc-icon" v-if="verifyResetPassword" type="check-circle" theme="filled" />
									<a-icon v-else class="right-icon err-icon" type="close-circle" theme="filled" />
								</div>
							</a-form-item>
							<a-form-item label="Confirm Password">
								<a-input-password
									:maxLength="30"
									size="large"
									placeholder="Please Confirm Password"
									type="password"
									@change="checkConfirmPassword"
									v-decorator="[
										'confirmPassword',
										{
											rules: [
												{
													required: true,
													message: 'Please Confirm Password',
													whitespace: true
												},
												{
													min: 6,
													message: 'Must be at least 6 characters'
												}
											]
										}
									]"
								/>
								<div class="tips-icon" v-if="verifyConfirmPassword !== null">
									<a-icon class="right-icon suc-icon" v-if="verifyConfirmPassword" type="check-circle" theme="filled" />
									<a-icon v-else class="right-icon err-icon" type="close-circle" theme="filled" />
								</div>
							</a-form-item>
						</template>
					</a-form>
					<a-row>
						<a-button style="width: 100%; margin-top: 24px" size="large" @click.native="setForgotPassword" type="primary">{{
							forgotPasswordStep.type === 4 ? 'Continue' : 'Reset Password'
						}}</a-button>
					</a-row>
					<a-row style="text-align: center">
						<a-button type="link" class="back-login" @click.native="forgotPassword">
							<a-icon type="arrow-left" />
							Back to login</a-button
						>
					</a-row>
				</div>
			</div>
		</a-layout-content>
		<GlobalFoot :link-list="setting.linkList" :copyright="setting.copyright"></GlobalFoot>
	</a-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import {
	_loginByuserAccount,
	apiCheckExist,
	apiCheckPassword,
	apiResetPassword,
	apiCheckMobile,
	apiSendCode,
	apiVerifyCode,
	apiSetPassword
} from '@/api/user'
import { apiLoanInterstRate } from '@/api/common'

export default {
	name: 'Login',
	data() {
		return {
			logging: false,
			error: '',
			form: this.$form.createForm(this, { name: 'normal_login' }),
			forgotForm: this.$form.createForm(this, { name: 'forgot_password' }),
			modalForm: this.$form.createForm(this, { name: 'tableForm' }),
			isResetPassword: false,
			verifyUserAccount: null,
			verifyUserPassword: null,
			verifyNewPassword: null,
			verifyRePassword: null,
			isForgotPassword: false,
			verifyDisabled: false,
			verifyResetPassword: null,
			verifyConfirmPassword: null,
			forgotPasswordStep: {
				type: 1,
				title: 'Forgot Password?',
				subTitle: 'Please Enter Your Mobile No. to Recelive a Verification Code'
			},
			setPassword: {}
		}
	},
	computed: {
		...mapGetters(['setting', 'enumerate', 'user', 'permission'])
	},
	methods: {
		resetPassword() {
			this.isResetPassword = !this.isResetPassword
			if (this.isResetPassword === false) {
				this.verifyUserAccount = null
				this.verifyUserPassword = null
				this.verifyNewPassword = null
				this.verifyRePassword = null
			}
			this.$nextTick(() => {
				this.form.validateFields(['userAccount'], (errors, values) => {
					if (!this.$Util.isEmpty(values.userAccount)) {
						this.checkUserAccount()
					}
				})
			})
		},
		checkUserAccount() {
			this.$nextTick(() => {
				this.form.validateFields(['userAccount', 'userPassword'], (errors, values) => {
					if (this.isResetPassword) {
						apiCheckExist({ username: values.userAccount }).then((result) => {
							this.verifyUserAccount = result
							if (!this.$Util.isEmpty(values.userPassword) && this.verifyUserAccount === true) {
								this.checkUserPassword()
							}
						})
					}
				})
			})
		},
		checkUserPassword() {
			this.$nextTick(() => {
				this.form.validateFields(['userAccount', 'userPassword'], (errors, values) => {
					if (this.isResetPassword) {
						if (this.$Util.isEmpty(values.userAccount)) {
							this.verifyUserPassword = false
							return false
						}
						if (this.verifyUserAccount === true) {
							apiCheckPassword({ username: values.userAccount, password: values.userPassword }).then((result) => {
								this.verifyUserPassword = result
							})
						}
					}
				})
			})
		},
		checkNewPassword() {
			this.$nextTick(() => {
				this.form.validateFields(['newPassword', 'reNewPassword'], (errors, values) => {
					let len = values.newPassword.length > 5 && values.newPassword.length < 31
					if (len) {
						this.verifyNewPassword = true
						if (values.reNewPassword) {
							this.checkRePassword()
						}
					} else {
						this.verifyNewPassword = false
					}
				})
			})
		},
		checkRePassword() {
			this.$nextTick(() => {
				this.form.validateFields(['newPassword', 'reNewPassword'], (errors, values) => {
					//  || this.$Util.isEmpty(values.reNewPassword)
					if (values.newPassword !== values.reNewPassword) {
						this.verifyRePassword = false
					} else {
						this.verifyRePassword = true
					}
				})
			})
		},
		async saveNewPassword() {
			let values = await this.form.validateFields()
			if (!this.verifyUserAccount || !this.verifyUserPassword || !this.verifyNewPassword || !this.verifyRePassword) {
				this.$message.error('error')
				return false
			}
			await apiResetPassword({
				username: values.userAccount,
				password: values.userPassword,
				newPassword: values.newPassword,
				confirmPassword: values.reNewPassword
			}).then(() => {
				this.isResetPassword = false
				this.form.resetFields()
			})
		},
		setLanguage(index) {
			this.$store.commit('setting/setLang', index)
		},
		changeEnumerateByPermission() {
			// 根据权限值修改列表能显示的值
			if (this.permission.limitStatus.range) {
				let newRangeList = this.enumerate.rangeList.filter((i) => this.permission.range.includes(i.value))
				this.$store.commit('enumerate/_setRangeList', newRangeList)
			}
			if (this.permission.limitStatus.team) {
				let newTeamList = this.enumerate.teamList.filter((i) => i.label.toUpperCase() === 'ALL' || this.permission.team.includes(i.value))
				this.$store.commit('enumerate/_setTeamList', newTeamList)
			}
			this.$store.commit('enumerate/pushAllTeamOption')

			// 根据权限值生成按钮的可显示表
			let visibilityMap = {}
			let pageMap = this.permission.pageMap
			for (let [pageName, pageInfo] of Object.entries(pageMap)) {
				visibilityMap[pageName] = {}
				for (let [buttonKey, buttonInfo] of Object.entries(pageInfo.sub)) {
					let buttonId = buttonKey
					let pageIdInfo = this.permission.ActionList.filter((i) => i.name === pageName)[0]
					if (pageIdInfo && pageIdInfo.sub) {
						let sub = pageIdInfo.sub
						let isVisibility = sub.filter((i) => i.name === buttonId).length !== 0
						visibilityMap[pageName][buttonKey] = isVisibility
					} else {
						visibilityMap[pageName][buttonKey] = false
					}
				}
			}
			this.$store.commit('permission/setVisibility', visibilityMap)
		},
		// 对display_order进行排序
		sortByOrder(a, b) {
			return a.display_order - b.display_order
		},
		// 返回排序好的数据
		handleSortNav(menus) {
			if (!menus) return []
			return menus
				.map((nav) => {
					if (nav.sub) {
						nav.sub = nav.sub.sort(this.sortByOrder)
					}
					return nav
				})
				.sort(this.sortByOrder)
		},
		async onSubmit(e) {
			e.preventDefault()
			if (this.isResetPassword) {
				this.saveNewPassword()
				return false
			}
			// 验证表单数据
			let values = await this.form.validateFields()
			this.logging = true
			// 登录
			try {
				let token = 'Basic ' + btoa(values.userAccount + ':' + values.userPassword)
				sessionStorage.setItem('token', token)
				let accountData = await _loginByuserAccount()
				// 获取用户菜单权限
				this.$store.commit('permission/setUserPermissions', accountData.authorities)
				let authorities = accountData.authorities
				let navPermissions = this.initUserNavPermission(authorities)
				this.$store.commit('navMenu/setNavMenu', navPermissions)

				// let salesmanList = (await apiAllSalesmanList())
				// this.$store.commit('enumerate/setSalesmanList', salesmanList)
				let loanInterstRate = await apiLoanInterstRate()
				this.$store.commit('user/setLoanInterstRate', loanInterstRate.value)

				// 保存用户登录信息
				this.$store.commit('user/setLoginUser', {
					userId: accountData.id,
					userName: accountData.username,
					isLogined: true
				})

				this.$store.dispatch('enumerate/initEnums')
				await this.$router.push('/home')
			} catch (e) {
				sessionStorage.removeItem('token')
				this.logging = false
				throw e
			}

			this.logging = false
		},
		initUserNavPermission(authorities) {
			let permissionsMap = {
				'menus.permission': 'ROLE_AUTHORITY_MANAGEMENT',
				'menus.permissionMenu.role': 'ROLE_RO_MANAGEMENT',
				'menus.permissionMenu.user': 'ROLE_ACCOUNT_MANAGEMENT',
				'menus.permissionMenu.reviewSetting': 'ROLE_CHECK_SETTING',
				'menus.customerManagement': 'ROLE_CUSTOMER_MANAGEMENT',
				'menus.customerManagementMenu.clue': 'ROLE_CUSTOMER_LEADS',
				'menus.customerManagementMenu.intention': 'ROLE_INTENTION_CUSTOMERS',
				'menus.customerManagementMenu.apply': 'ROLE_APPLY_CUSTOMER',
				'menus.customerManagementMenu.adsClients': 'ROLE_AD_CLIENT_LEAVE',
				'menus.customerManagementMenu.lending': 'ROLE_DISBURESE_CUSTEMERS',
				'menus.customerManagementMenu.loansrejected': 'ROLE_LOAN_REJECT',
				'menus.employeeManagement': 'ROLE_STAFF_MANAGEMENT',
				'menus.employeeManagementMenu.salesman': 'ROLE_SALESMAN_MANAGEMENT',
				'menus.employeeManagementMenu.branchman': 'ROLE_STORE_MANAGEMENT',
				// 'menus.review': 'ROLE_REVIEW',
				// 'menus.reviewMenu.userFlow': 'ROLE_BILL_REVIEW',
				// 'menus.reviewMenu.management': 'ROLE_KEY_WORDS',
				'menus.dictionary': 'ROLE_CODE_MANAGEMENT',
				'menus.gpsCar': 'ROLE_CAR_GPS',
				'menus.gpsMenu.list': 'ROLE_GPS_LIST',
				'menus.gpsMenu.report': 'ROLE_GPS_ERROR_REPORT',
				// 'menus.gpsMenu.setting': 'ROLE_GPS_SETTING',
				'menus.loanData': 'ROLE_LOAN_APPLY_INFO',
				'menus.loanDataMenu.applicationList': 'ROLE_APPLY_INFO',
				'menus.loanDataMenu.loanList': 'ROLE_DISBURESE_INFO',
				'menus.loanDataMenu.pendingLoan': 'ROLE_PENGING_LOAN',
				'menus.loanReview': 'ROLE_LOAN_APPLY_CHECK',
				'menus.loanMenu.applicationReview': 'ROLE_APPLY_INFO_CHECK',
				'menus.loanMenu.loanDataReview': 'ROLE_DISBURSE_INFO_CHECK',
				'menus.finance': 'ROLE_FINANCE',
				'menus.financeMenu.upfrontfees': 'ROLE_UPFRONT',
				'menus.financeMenu.overpayment': 'ROLE_OVERPAID',
				'menus.financeMenu.repayment': 'ROLE_REPAYMENT_CER',
				'menus.financeMenu.repaymentEntrty': 'ROLE_REPAY_RECORD',
				'menus.financeMenu.repaymentReview': 'ROLE_REPAYMENT_CHECK',
				'menus.collections': 'ROLE_COLLECTIONS',
				'menus.collectionsMenu.loansDisbursed': 'ROLE_LOAN_DISBURSED',
				'menus.collectionsMenu.overdue': 'ROLE_OVERDUE',
				'menus.accountChecking': 'ROLE_ACCOUNTING',
				'menus.accountCheckingMenu.mpesaAccountChecking': 'ROLE_ACCOUNTING_MPESA',
				'menus.accountCheckingMenu.bankAccountChecking': 'ROLE_ACCOUNTING_DTB',
				'menus.insuranceRenewal': 'ROLE_IPF_OR_INSURANCE_RENEWAL',
				'menus.insuranceRenewalMenu.renewalList': 'ROLE_RENEWAL_LIST',
				'menus.insuranceRenewalMenu.renewalReview': 'ROLE_RENEWAL_REVIEW',
				'menus.insuranceRenewalMenu.renewalPending': 'ROLE_RENEWAL_PENDING',
				'menus.insuranceRenewalMenu.renewalDisbursed': 'ROLE_RENEWAL_DISBURSED',
				'menus.emergencyLoan': 'ROLE_EMERGENCY_OR_CASH_PAP_LOAN',
				'menus.emergencyLoanMenu.clientList': 'ROLE_EMERGENCY_APPLY_LIST',
				'menus.emergencyLoanMenu.dataReview': 'ROLE_EMERGENCY_APPLY_CHECK',
				'menus.emergencyLoanMenu.loanList': 'ROLE_EMERGENCY_DISBURSE_LIST',
				'menus.emergencyLoanMenu.loanReview': 'ROLE_EMERGENCY_DISBURSE_CHECK',
				'menus.emergencyLoanMenu.pendingList': 'ROLE_EMERGENCY_DISBURSE_WAITING',
				'menus.emergencyLoanMenu.disbursed': 'ROLE_EMERGENCY_DISBURSE_SUCCESS'
			}
			let navPermissions = [
				{
					name: 'menus.permission',
					path: 'permission',
					sub: [
						{
							name: 'menus.permissionMenu.role',
							path: 'role'
						},
						{
							name: 'menus.permissionMenu.user',
							path: 'user'
						},
						{
							name: 'menus.permissionMenu.reviewSetting',
							path: 'reviewSetting'
						}
					]
				},
				{
					name: 'menus.customerManagement',
					path: 'customerManagement',
					sub: [
						{
							name: 'menus.customerManagementMenu.clue',
							path: 'clue'
						},
						{
							name: 'menus.customerManagementMenu.intention',
							path: 'intention'
						},
						{
							name: 'menus.customerManagementMenu.adsClients',
							path: 'adsClients'
						},
						{
							name: 'menus.customerManagementMenu.apply',
							path: 'apply'
						},
						{
							name: 'menus.customerManagementMenu.lending',
							path: 'lending'
						},
						{
							name: 'menus.customerManagementMenu.loansrejected',
							path: 'loansrejected'
						}
					]
				},
				{
					name: 'menus.employeeManagement',
					path: 'employeeManagement',
					sub: [
						{
							// 业务员管理
							name: 'menus.employeeManagementMenu.salesman',
							path: 'salesman'
						},
						{
							// 门店管理
							name: 'menus.employeeManagementMenu.branchman',
							path: 'branchman'
						}
					]
				},
				{
					name: 'menus.loanData',
					path: 'loandata',
					sub: [
						{
							// 申请资料 list
							name: 'menus.loanDataMenu.applicationList',
							path: 'applicationlist'
						},
						{
							//   - 放款资料 list
							name: 'menus.loanDataMenu.loanList',
							path: 'loanlist'
						},
						{
							//   - 待放款 list
							name: 'menus.loanDataMenu.pendingLoan',
							path: 'pendinglist'
						}
					]
				},
				{
					name: 'menus.insuranceRenewal',
					path: 'insuranceRenewal',
					sub: [
						{
							name: 'menus.insuranceRenewalMenu.renewalList',
							path: 'renewalList'
						},
						{
							name: 'menus.insuranceRenewalMenu.renewalReview',
							path: 'renewalReview'
						},
						{
							name: 'menus.insuranceRenewalMenu.renewalPending',
							path: 'renewalPending'
						},
						{
							name: 'menus.insuranceRenewalMenu.renewalDisbursed',
							path: 'renewalDisbursed'
						}
					]
				},
				{
					name: 'menus.emergencyLoan',
					path: 'emergencyLoan',
					sub: [
						{
							name: 'menus.emergencyLoanMenu.clientList',
							path: 'clientList'
						},
						{
							name: 'menus.emergencyLoanMenu.dataReview',
							path: 'dataReview'
						},
						{
							name: 'menus.emergencyLoanMenu.loanList',
							path: 'loanList'
						},
						{
							name: 'menus.emergencyLoanMenu.loanReview',
							path: 'loanReview'
						},
						{
							name: 'menus.emergencyLoanMenu.pendingList',
							path: 'pendingList'
						},
						{
							name: 'menus.emergencyLoanMenu.disbursed',
							path: 'disbursed'
						}
					]
				},
				{
					name: 'menus.loanReview',
					path: 'loanreview',
					sub: [
						{
							name: 'menus.loanMenu.applicationReview',
							path: 'applicationReview'
						},
						{
							name: 'menus.loanMenu.loanDataReview',
							path: 'loanDataReview'
						}
					]
				},
				{
					name: 'menus.finance',
					path: 'finance',
					sub: [
						{
							name: 'menus.financeMenu.repaymentEntrty',
							path: 'repaymentEntrty'
						},
						{
							name: 'menus.financeMenu.repayment',
							path: 'repayment'
						},
						{
							name: 'menus.financeMenu.repaymentReview',
							path: 'repaymentReview'
						},
						{
							name: 'menus.financeMenu.upfrontfees',
							path: 'upfrontfees'
						},
						{
							name: 'menus.financeMenu.overpayment',
							path: 'overpayment'
						}
					]
				},
				{
					name: 'menus.collections',
					path: 'collections',
					sub: [
						{
							name: 'menus.collectionsMenu.loansDisbursed',
							path: 'loansDisbursed'
						},
						{
							name: 'menus.collectionsMenu.overdue',
							path: 'overdue'
						}
					]
				},
				{
					name: 'menus.accountChecking',
					path: 'accountChecking',
					sub: [
						{
							name: 'menus.accountCheckingMenu.mpesaAccountChecking',
							path: 'mpesaAccountChecking'
						},
						{
							name: 'menus.accountCheckingMenu.bankAccountChecking',
							path: 'bankAccountChecking'
						}
					]
				},
				// {
				// 	name: 'menus.review',
				// 	path: 'review',
				// 	sub: [
				// 		// {
				// 		// 	name: 'menus.reviewMenu.userFlow',
				// 		// 	path: 'userFlow'
				// 		// },
				// 		{
				// 			name: 'menus.reviewMenu.management',
				// 			path: 'management'
				// 		}
				// 	]
				// },
				{
					name: 'menus.gpsCar',
					path: 'gps',
					sub: [
						{
							name: 'menus.gpsMenu.list',
							path: 'list'
						}
						// {
						//   name: "menus.gpsMenu.report",
						//   path: "report"
						// },
						// {
						// 	name: 'menus.gpsMenu.setting',
						// 	path: 'setting'
						// }
					]
				},
				{
					name: 'menus.dictionary',
					path: 'dictionarylist'
				}
			]
			let userNavPermissions = navPermissions.slice()
			let menuPermissionindexList = []
			for (let [i, permission] of Object.entries(navPermissions)) {
				let roleKey = permissionsMap[permission.name]
				if (authorities.findIndex((v) => v.authority === roleKey) === -1) {
					menuPermissionindexList.unshift(i)
				} else if (permission.sub) {
					let indexList = []
					for (let [i, sub] of Object.entries(permission.sub)) {
						let roleKey = permissionsMap[sub.name]
						if (authorities.findIndex((v) => v.authority === roleKey) === -1) {
							indexList.unshift(i)
						}
					}
					for (let i of indexList) {
						permission.sub.splice(i, 1)
					}
				}
			}
			for (let i of menuPermissionindexList) {
				userNavPermissions.splice(i, 1)
			}
			return userNavPermissions
		},
		forgotPassword() {
			this.forgotPasswordStep.type = 1
			this.forgotPasswordStep.title = 'Forgot Password?'
			this.forgotPasswordStep.subTitle = 'Please Enter Your Mobile No. to Recelive a Verification Code'
			this.verifyResetPassword = null
			this.verifyConfirmPassword = null
			this.isForgotPassword = !this.isForgotPassword
		},
		checkMobile(e) {
			this.$nextTick(() => {
				this.forgotForm.validateFields(['userName', 'mobileNumber'], (errors, values) => {
					if (e.target.value.length === 9) {
						apiCheckMobile({
							username: values.userName, //用户名
							mobile: values.mobileNumber //手机号码
						}).then((res) => {
							this.verifyCheckMobile = true
						})
					}
				})
			})
		},
		checkResetPassword(e) {
			this.$nextTick(() => {
				this.forgotForm.validateFields(['resetPassword'], (errors, values) => {
					let len = values.resetPassword.length > 5 && values.resetPassword.length < 31
					if (len) {
						this.verifyResetPassword = true
						this.checkConfirmPassword()
					} else {
						if (this.$Util.isEmpty(values.resetPassword)) {
							this.verifyResetPassword = null
						} else {
							this.verifyResetPassword = false
						}
					}
				})
			})
		},
		checkConfirmPassword() {
			this.$nextTick(() => {
				this.forgotForm.validateFields(['resetPassword', 'confirmPassword'], (errors, values) => {
					if (this.$Util.isEmpty(values.confirmPassword)) {
						this.verifyConfirmPassword = null
					} else {
						this.verifyConfirmPassword = values.resetPassword === values.confirmPassword
					}
				})
			})
		},
		getApiSendCode() {
			apiSendCode(this.setPassword).then(() => {
				this.$message.success('Resend Success')
			})
		},
		setForgotPassword() {
			if (this.forgotPasswordStep.type === 1) {
				this.forgotForm.validateFields(['userName', 'mobileNumber'], (errors, values) => {
					if (errors) {
						return false
					}
					this.setPassword = {
						username: values.userName,
						mobile: values.mobileNumber
					}
					apiSendCode({
						username: values.userName,
						mobile: values.mobileNumber
					}).then((res) => {
						this.forgotPasswordStep.title = 'Verification'
						this.forgotPasswordStep.subTitle = 'Please Enter The 6 Digit Code Sent To Your Mobile Phone'
						this.forgotPasswordStep.type++
					})
				})
			} else if (this.forgotPasswordStep.type === 2) {
				this.forgotForm.validateFields(['codeNumber'], (errors, values) => {
					if (errors) {
						return false
					}
					this.setPassword.verifyCode = values.codeNumber
					apiVerifyCode(this.setPassword).then(() => {
						this.forgotPasswordStep.type++
						this.forgotPasswordStep.title = 'Set new password'
						this.forgotPasswordStep.subTitle = 'Your new password must be different from previously used password'
					})
				})
			} else if (this.forgotPasswordStep.type === 3) {
				this.forgotForm.validateFields(['resetPassword', 'confirmPassword'], (errors, values) => {
					let len = values.resetPassword.length > 5 && values.confirmPassword.length < 31
					if (values.resetPassword !== values.confirmPassword) {
						this.$message.error('The two passwords you typed do not match')
						return false
					}
					if (len && values.resetPassword === values.confirmPassword) {
						apiSetPassword({
							...this.setPassword,
							newPassword: values.resetPassword, //新密码
							confirmPassword: values.confirmPassword //确认新密码
						}).then(() => {
							this.forgotPasswordStep.type++
							this.forgotPasswordStep.title = 'Password Reset'
							this.forgotPasswordStep.subTitle = 'Your password has been successfully reset. Click below to log in'
						})
					}
				})
			} else if (this.forgotPasswordStep.type === 4) {
				this.forgotPasswordStep.type = 1
				this.forgotPasswordStep.title = 'Forgot Password?'
				this.forgotPasswordStep.subTitle = 'Please Enter Your Mobile No. to Recelive a Verification Code'
				this.isForgotPassword = false
				console.log(this.setPassword.username)
				this.$nextTick(() => {
					this.form.setFieldsValue({
						userAccount: this.setPassword.username
					})
				})
			}
		}
	}
}
</script>

<style lang="less" scoped>
@keyframes rotate {
	0% {
		transform: translate(-50%, 0) rotateZ(0deg);
	}
	50% {
		transform: translate(-50%, -2%) rotateZ(180deg);
	}
	100% {
		transform: translate(-50%, 0%) rotateZ(360deg);
	}
}
.container {
	// background: rgb(118, 218, 255);
	background: #fff;
	background-size: 100%;
	position: relative;
	align-items: center;
	min-height: 100vh;
	overflow: hidden;
	z-index: 0;
	// &:before,
	// &:after {
	// 	content: '';
	// 	position: absolute;
	// 	left: 50%;
	// 	min-width: 300vw;
	// 	min-height: 300vw;
	// 	background-color: #fff;
	// 	animation-name: rotate;
	// 	animation-iteration-count: infinite;
	// 	animation-timing-function: linear;
	// }
	// &:before {
	// 	bottom: 15vh;
	// 	border-radius: 45%;
	// 	animation-duration: 10s;
	// }
	// &:after {
	// 	bottom: 12vh;
	// 	opacity: 0.5;
	// 	border-radius: 47%;
	// 	animation-duration: 10s;
	// }
	.sign-header {
		justify-content: center;
		align-items: center;
		text-align: center;
		.logo {
			height: 44px;
			vertical-align: top;
			margin-right: 16px;
		}
		.title {
			font-size: 33px;
			color: rgba(0, 0, 0, 0.85);
			font-weight: 600;
			position: relative;
		}
	}
	.desc {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.45);
		margin-top: 12px;
		margin-bottom: 40px;
	}
	.content {
		position: relative;
		z-index: 1;
		padding: 32px 0;
		overflow: auto;
		flex: 1 1;
		width: 100%;
		background: url('../assets/login.svg') no-repeat center 110px;
		@media (min-width: 768px) {
			padding: 112px 0 24px;
		}
		.login {
			width: 468px;
			margin: 0 auto;
			.forgot-password {
				.title {
					padding: 18px 0 20px;
					font-size: 40px;
					font-weight: bold;
					color: #999;
					text-align: center;
				}
				.subTitle {
					font-size: 16px;
					margin-bottom: 20px;
					color: #ccc;
					text-align: center;
				}
				.ant-form-item {
					margin-bottom: 0;
				}
				.back-login {
					margin-top: 16px;
					color: #ccc;
					text-align: center;
					.anticon-arrow-left {
						margin-right: 10px;
					}
				}
			}
			@media screen and (max-width: 576px) {
				width: 95%;
			}
			@media screen and (max-width: 320px) {
				.captcha-button {
					font-size: 14px;
				}
			}
			.tips-icon {
				width: 400px;
				z-index: 2;
				position: absolute;
				top: -12px;
				left: 490px;
				line-height: 40px;
				.right-icon {
					font-size: 25px;
					color: red;
					margin-right: 10px;
					line-height: 40px;
				}
				.suc-icon {
					line-height: 40px;
					color: green;
				}
				span {
					color: #000;
				}
				.tips {
					color: #ccc;
				}
			}
			.icon {
				font-size: 18px;
				color: rgba(0, 0, 0, 0.25);
				vertical-align: middle;
				cursor: pointer;
				transition: color 0.3s;
				&:hover {
					color: #1890ff;
				}
			}
		}
	}
}
</style>
